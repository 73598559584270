<template>
  <div>
    <b-card-code title="Area Management">
      <el-table
        :data="tableData.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
        style="width: 100%"
      >
        <el-table-column
          label="ID"
          prop="id"
          stripe
        />
        <el-table-column
          label="Area Name"
          prop="name"
        />
        <el-table-column
          align="right"
        >
          <template
            slot="header"
            slot-scope="scope"
          >
            <div class="d-flex">
              <el-button
                size="mini"
                type="success"
                class="mr-1"
                @click="handleAdd"
              >Add New Area</el-button>
              <el-input
                v-model="search"
                size="mini"
                placeholder="Type to search"
              />
            </div>
          </template>
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleEdit(scope.row)"
            >Edit</el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
            >Delete</el-button>
          </template>
        </el-table-column>
      </el-table>

    </b-card-code>
    <el-dialog
      title="Add New Area"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-form
        ref="form"
        :model="area"
        label-width="120px"
      >
        <el-form-item label="Area name">
          <el-input v-model="area.name" />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="save"
        >Save</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Edit Area"
      :visible.sync="dialogVisibleEdit"
      width="30%"
    >
      <el-form
        ref="form"
        :model="area"
        label-width="120px"
      >
        <el-form-item label="Area name">
          <el-input v-model="area.name" />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisibleEdit = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="update"
        >Update</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'

export default {
  name: 'Area',
  components: { BCardCode },
  data() {
    return {
      dialogVisible: false,
      dialogVisibleEdit: false,
      isp_id: JSON.parse(localStorage.getItem('userData')),
      tableData: [],
      search: '',
      area: {},
    }
  },
  mounted() {
    this.getAreaList()
  },
  methods: {
    getAreaList() {
      this.$http
        .get(`${process.env.VUE_APP_BASEURL}/api/areas?isp_id=${this.isp_id.id}`)
        .then(res => {
          this.tableData = res.data.data
        })
    },
    save() {
      this.$http
        .post(`${process.env.VUE_APP_BASEURL}/api/areas`, {
          isp_id: this.isp_id.id,
          name: this.area.name,
        })
        .then(res => {
          this.postAction(res)
        })
    },
    update() {
      this.$http
        .put(`${process.env.VUE_APP_BASEURL}/api/areas/${this.area.id}`, {
          isp_id: this.isp_id.id,
          name: this.area.name,
        })
        .then(res => {
          this.postAction(res)
        })
    },
    postAction(res) {
      this.dialogVisible = false
      this.dialogVisibleEdit = false
      if (res.status !== 200) {
        this.$notify({
          type: 'error',
          message: res.data.message,
        })
      }

      this.$notify({
        type: 'success',
        message: res.data.message,
      })
      this.getAreaList()
    },
    handleAdd() {
      this.dialogVisible = true
      this.area = {}
    },
    handleEdit(row) {
      this.dialogVisibleEdit = true
      this.area = row
    },
    handleDelete(row) {
      this.$confirm('Are you sure to close this dialog?')
        .then(() => {
          this.deleteArea(row.id)
        })
        .catch(() => {})
    },
    deleteArea(areaId) {
      this.$http
        .delete(`${process.env.VUE_APP_BASEURL}/api/areas/${areaId}`)
        .then(res => {
          this.postAction(res)
        })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
